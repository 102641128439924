import React from "react"

import { Helmet } from 'react-helmet'

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"

import img04 from "../../../src/images/portfolio/project-deliveries-04.gif"

export const query = graphql`
    query {
        img01: file(relativePath: { eq: "portfolio/project-deliveries-01.jpg" }) {
            ...projectImg
        }
        img02: file(relativePath: { eq: "portfolio/project-deliveries-02.jpg" }) {
            ...projectImg
        }
        img03: file(relativePath: { eq: "portfolio/project-deliveries-03-long.png" }) {
            ...projectImg
        }
    }
`
const ProjectDeliveriesPage = ({ data }) => (
  <>
    <Seo title="Delivery tracker project - Scott Luxford" />
    <Helmet>
      <body className="projects projects-theme-deliveries" />
    </Helmet>
    <Header />
    <main className="project" id="top">
        <article>

			<Link className="project-crumb" to="/projects"><span className="sr-only">Back to</span><span role="presentation">&lt;</span> Projects</Link>
			<h1 className="heading-offset">Delivery Tracker</h1>

			<ul>
				<li><strong>Role</strong>
				Senior UI/UX Designer &amp; Frontend Developer</li>

				<li><strong>Responsibilities</strong>
				User research, wireframes, concepts, prototypes, product design, AngularJS development, HTML, CSS, JavaScript</li>

				<li><strong>Company</strong>
				CSR Limited</li>

				<li><strong>When</strong>
				2014 - 2019</li>
			</ul>

			<section className="section section-text-first">

				<div className="section-text">
					<h2>Live delivery tracking</h2>

					<p>Designing the CSR Connect delivery tracking experience was a highlight of my time at CSR. Rewarding work; it was a broad project requiring lots of user research, assessing existing solutions, a suite of new icons designed, and presenting a lot of information to the user in a concise and intuitive way.</p>
					<p>On the frontend development side, the UI presented data from our own API via the Google Maps JavaScript API, including live delivery time estimates and eventually, real time GPS tracking of delivery vehicles<sup>*</sup>.</p>
					<p><sup>*</sup> <small>Once the right coordinates were coming in and trucks stopped appearing in the middle of the Pacific Ocean</small></p>
				</div>

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img01)} alt="A photograph of a hand holding a touchscreen smartphone running the CSR Connect deliveries feature'" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> We didn't have dedicated designers for our comms assets, so I often enjoyed a quick break from the UI to whip something up.</figcaption>
				</figure>

			</section>

			<section className="section section-media-first">

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img02)} alt="A sketchbook showing early drawings of the CSR Connect delivery tracking interface design, showing various status on a list and a mode with deliveries shown on a map" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> My first sketches of the delivery tracker for a small screen</figcaption>
				</figure>

				<div className="section-text">
					<h2>Just the right amount of information</h2>

					<p>Data gathered from customer service representatives around what queries they were fielding for deliveries revealed that our users needed substantial information displayed in order to reliably track their (multiple) orders (Side note: This continued to grow: I think the final implementation was 3 times richer).</p>

					<p>We opted out of taking a small-screen-first approach in the first iteration, as our customer data indicated our target segment were primarily at a desktop. When it came to designing the small screen experience, we looked through a new lens focused around onsite users, who were identified as a growth opportunity with found to have simpler and more-immediate needs, eg: "Where is the 7am delivery I'm expecting this morning?" rather than "How many deliveries am I expecting tomorrow and are they scheduled correctly?".</p>

					<p>Following on from this once the feature was in customer hands, customer feedback was that active notifications were needed - so the team implemented SMS notifications that securely linked directly to the delivery details, which really rounded out the flow.</p>
				</div>

			</section>

			<section className="section section-text-first section-media-narrow">

				<div className="section-text">
					<h2>Designing a language both familiar and new</h2>

					<p>There was plenty of convention and inspiration for delivery tracker type applications at the time we were designing. Auspost were working hard to simplify their mobile apps, and most courier companies were improving their messaging too through digital transformation.</p>

					<p>So while we set abut designing something for our brand within our design system, the task was really about adapting the growing design language that was developing around delivery tracking and placing it in the CSR context, only adding to it where we needed to.</p>

					<p>The predominant language around our delivery tracker was textual - best for usability and accessibility, especially at the introduction of a new feature. As users become familiar with the UI or begin using the map view, the iconography becomes important for at-a-glance indications of status.</p>

				</div>

				<figure className="section-media">
					<img src={img04} alt="An animated icon for a delivery truck, showing a filling progress bar on it's trailer" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> An early animated delivery status icon: This pre-dated our live GPS tracking, so progress bar-like animation was added to indicate a sense of movement.</figcaption>
				</figure>

			</section>

			<section className="section section-solo">

				<figure className="section-media">
					<GatsbyImage image={getImage(data.img03)} alt="Icons from the CSR Connect delivery tracker" />
					<figcaption><i className="fas fa-caret-up fa-sm"></i> The base suite of icons for deliveries and pickups; showing status for pending, enroute, arriving and complete status.</figcaption>
				</figure>

			</section>

        </article>

        <p className="back-links"><a href="#top">Back to top</a> <span role="presentation">/</span> <Link to="/projects">Back to projects</Link></p>
    </main>
    <Footer />
  </>
)

export default ProjectDeliveriesPage
